'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { ProductCard } from 'product-card/components/ProductCard'
import { ProductCardProvider } from 'product-card/context/ProductCardContext'
import { useProductDataContext } from 'product-card/hooks/useProductDataContext'
import { type ProductCardProps } from 'product-card/types'
import { useProductInfo } from 'product/services/productInfo/client'
import { useState } from 'react'

export const ClientProductCard = ({
	productId,
	lookId,
	colorId,
	imageSortCriteria,
	priority,
	dataTestId,
	isTwoColumnSize,
	className,
	location,
	productListId,
	showDiscount,
	noDraggable,
}: ProductCardProps) => {
	const {
		country: { countryISO, languageISO },
	} = useMasterData()
	const [shouldFetch, setShouldFetch] = useState(false)

	const { productData: productDataFromServer } = useProductDataContext()
	const isProductDataFromServer = !!productDataFromServer

	const { data: productDataFromClient, isLoading } = useProductInfo({
		productId,
		country: countryISO,
		language: languageISO,
		enabled: Boolean(!isProductDataFromServer && shouldFetch),
	})

	const productData = productDataFromServer ?? productDataFromClient

	return (
		<ProductCardProvider
			initialProductId={productId}
			initialDataTestId={dataTestId}
			initialLookId={lookId}
			initialColorId={colorId}
			location={location}
			productListId={productListId}
			noDraggable={noDraggable}
		>
			<ProductCard
				className={className}
				product={productData}
				isProductLoading={!shouldFetch || (shouldFetch && isLoading)}
				imageSortCriteria={imageSortCriteria}
				priority={priority}
				isTwoColumnSize={isTwoColumnSize}
				setShouldFetch={setShouldFetch}
				showDiscount={showDiscount}
			/>
		</ProductCardProvider>
	)
}
