'use client'

import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { ProductDisclaimer } from 'product/types/Product'
import { useMemo } from 'react'

import styles from './DisclaimerMessage.module.scss'

const sizeDisclaimerKey = {
	[ProductDisclaimer.UP_DISCLAIMER]:
		'product.sizeSelector.smallSizing.helpmessage',
	[ProductDisclaimer.DOWN_DISCLAIMER]:
		'product.sizeSelector.bigSizing.helpmessage',
	[ProductDisclaimer.SIZEGUIDE_DISCLAIMER]: null,
	[ProductDisclaimer.MATERNITY_DISCLAIMER]: null,
}

export function DisclaimerMessage({
	type,
}: {
	type: ProductDisclaimer
}): React.ReactNode {
	const { t } = useLabels()
	const key = useMemo(() => sizeDisclaimerKey[type], [type])

	if (!key) {
		return null
	}

	return <p className={styles.disclaimer}>{t(key)}</p>
}
