'use client'

import type { Product } from 'product/types'
import { type ReactNode, createContext, useMemo, useRef } from 'react'

interface ProductDataContextProps {
	productData: Product
}

interface ProductDataProviderProps {
	initialProductData: Product
	children: ReactNode
}

export const ProductDataContext = createContext<ProductDataContextProps | null>(
	null
)

export const ProductDataProvider = ({
	children,
	initialProductData,
}: ProductDataProviderProps) => {
	const productDataRef = useRef(initialProductData)

	const values = useMemo(
		() => ({
			productData: productDataRef.current,
		}),
		[]
	)

	return (
		<ProductDataContext.Provider value={values}>
			{children}
		</ProductDataContext.Provider>
	)
}
