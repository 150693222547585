import type { Brand } from 'types/brands'

import type { StockAvailability } from './Stock'

export interface Product {
	id: string
	assetsDomain: string
	colors: ProductColor[]
	families: ProductFamily[]
	genderId: ProductGenderId
	brandId: Brand['id']
	model: string
	name: string
	nameEn: string
	reference: string
	seller: 'mango'
	tags?: string[]
	url?: string
}

export interface ProductFamily {
	id: string
	label: string
	labelEn: string
	isMainFamily: boolean
	hasSizeGuide: boolean
	erpId?: string
}

export interface ProductColor {
	id: string
	bulletImg: string
	customizable: boolean
	label: string
	looks: Record<string, ProductLook>
	sizes: ProductSize[]
	relatedProductId?: string
	relatedProductUrl?: string
	disclaimer?: ProductDisclaimer
}

export interface ProductSize {
	id: string
	label: string
	plusSize: boolean
	extraInfo?: string
	relatedProductId?: string
	relatedProductUrl?: string
}

export interface ProductLook {
	// Partial since TS would require every ProductImageType enum value to be present otherwise.
	images: Partial<Record<ProductImageType, ProductImage>>
}

export interface ProductImage {
	img: string
	alt: string
}

export enum ProductImageTypeEnum {
	B = 'B',
	D0 = 'D0',
	D1 = 'D1',
	D2 = 'D2',
	D3 = 'D3',
	D4 = 'D4',
	D5 = 'D5',
	D6 = 'D6',
	D7 = 'D7',
	D8 = 'D8',
	D9 = 'D9',
	F = 'F',
	O1 = 'O1',
	O2 = 'O2',
	R = 'R',
}

export enum MandatoryProductImageTypeEnum {
	B = ProductImageTypeEnum.B,
	F = ProductImageTypeEnum.F,
	O1 = ProductImageTypeEnum.O1,
}

export type ProductImageType = keyof typeof ProductImageTypeEnum

export type MandatoryProductImageType =
	keyof typeof MandatoryProductImageTypeEnum

export type ProductFullSizeInfo = ProductSize & StockAvailability

export type ProductGenderId =
	| 'M' // Mango Woman
	| 'H' // Mango Man
	| 'T' // Unisex
	| 'A' // Kids niña
	| 'O' // Kids niño
	| 'S' // Sport
	| 'I' // Intimates
	| 'V' // Violeta
	| 'Q' // Teen niña
	| 'P' // Teen niño
	| 'F' // Kids recién nacido
	| 'E' // Kids bebé niña
	| 'D' // Kids bebé niño
	| 'U' // Mango Casa Unisex
	| 'C' // Mango Casa Female
	| 'J' // Mango Casa Male

export enum ProductDisclaimer {
	UP_DISCLAIMER = 'BUY_UP',
	DOWN_DISCLAIMER = 'BUY_DOWN',
	SIZEGUIDE_DISCLAIMER = 'SIZEGUIDE',
	MATERNITY_DISCLAIMER = 'MATERNITY',
}
