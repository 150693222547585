'use client'

import { ProductDataContext } from 'product-card/context/ProductDataContext'
import { useContext } from 'react'

export const useProductDataContext = () => {
	const productDataContext = useContext(ProductDataContext)

	if (!productDataContext) {
		return {
			productData: null,
		}
	}

	return productDataContext
}
