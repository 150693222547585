import type { GetterProductCardSettings } from 'product-card/context/ProductCardConsumerLayoutContext/ProductCardConsumerLayoutContext'
import { ProductCardIcon } from 'product-card/types'

import { getProductCardSettings } from '../getProductCardSettings'

export const getProductCardS: GetterProductCardSettings = (ctx) => ({
	...getProductCardSettings(ctx),
	hasDotsIndicator: false,
	shouldRenderFeatured: false,
	showActionIcon: ctx.hasOnlineSale
		? ProductCardIcon.SizeSheet
		: ProductCardIcon.Favorite,
	shouldRenderColorSheetButton: false,
	shouldRenderAddButtonAsPrimary: false,
	hasSizeSheetButton: false,
	hasColorPicker: true,
	shouldRenderLinkLabels: true,
})
