import type { GetterProductCardSettings } from '../../../context/ProductCardConsumerLayoutContext/ProductCardConsumerLayoutContext'
import { getProductCardSettings } from '../getProductCardSettings'

export const getProductCardL: GetterProductCardSettings = (ctx) => ({
	...getProductCardSettings(ctx),
	shouldRenderColorSheetButton: false,
	shouldRenderSlider: true,
	shouldRenderFeatured: true,
	shouldRenderLinkLabels: true,
})
